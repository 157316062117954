<template>
    <section>
        <modal ref="verMapa" titulo="Ubicar tienda en el mapa" tamano="modal-xl" icon="location">
            <mapa ref="el_mapa" style="width:100%; height:50vh" />
        </modal>
    </section>
</template>
<script>
export default {
    data(){
        return{}
    },
    methods: {
        toggle(){
            this.$refs.verMapa.toggle();
        }
    }
}
</script>
